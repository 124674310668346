<template>
  <div v-if="closingTime" class="grid-x grid-padding-x">
    <div v-if="mode !== 'add'" class="cell large-12 medium-12 small-12">
      <span><h5>Anzahl Tage:&nbsp;<b style="font-weight: normal; color: cornflowerblue; font-size: larger">{{closingTime.closingDays}}</b></h5></span>
    </div>
    <div class="cell large-12 medium-12 small-12">
      <md-field :class="getValidationClass('name')">
        <label for="closingTime-name">Name</label>
        <md-input name="closingTime-name" id="closingTime-name" autocomplete="off"
                  v-model="closingTime.name" :disabled="sending || closingTime.fixed"/>
        <span class="md-error"
              v-if="!$v.closingTime.name.required">Name ist notwendig</span>
      </md-field>
    </div>
    <div class="cell large-0 medium-6 small-12">
    </div>
    <div class="cell small-12">
      <div v-if="!closingTime.fixed" class="md-layout">
        <div class="md-layout-item" style="padding-right: 15px;">
          <md-datepicker id="from-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="fromDate" md-immediately :disabled="sending"
                         :class="getValidationClass('from')" :md-disabled-dates="disabledClosedDays">
            <label>Startdatum</label>
            <span class="md-error" v-if="$v.closingTime.from.$model === 'Invalid date'">Startdatum ist notwendig</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item" style="padding-left: 15px;">
          <md-datepicker id="until-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="untilDate" md-immediately :disabled="sending"
                         :class="getValidationClass('until')" @md-opened="onOpenUntilDatePicker" :md-disabled-dates="disabledClosedDays">
            <label>Endedatum</label>
            <span class="md-error" v-if="$v.closingTime.until.$model === 'Invalid date'">Endedatum ist notwendig</span>
          </md-datepicker>
        </div>
      </div>
      <div v-else class="grid-x grid-padding-x">
        <div class="cell small-6">
          <md-field>
            <label for="closingTime-start">Startdatum</label>
            <md-input name="closingTime-start" v-model="fromDate" disabled/>
          </md-field>
        </div>
        <div class="cell small-6">
          <md-field>
            <label for="closingTime-end">Endedatum</label>
            <md-input name="closingTime-end" v-model="untilDate" disabled/>
          </md-field>
        </div>
      </div>
    </div>
    <div v-if="!closingTime.fixed" class="cell large-6 medium-6 small-6">
      <md-checkbox class="md-primary" v-model="closingTime.educational">Weiterbildung</md-checkbox>
    </div>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import Vue from 'vue';
  import {validationMixin} from 'vuelidate';
  import {
    required,
  } from 'vuelidate/lib/validators';

  export default {
    name: 'ClosingTimeForm',
    mixins: [validationMixin],

    props: {
      closingTime: {
        required: true
      },
      selectedYear: {
        required: true
      },
      mode: {
        required: true
      },
      sending: {
        required: true
      },
    },

    watch: {
      closingTime() {
        this.formatFromDate();
        this.formatUntilDate();
      },
    },

    created() {
      this.formatFromDate();
      this.formatUntilDate();
      this.initDatePickerSyncHandler(true);
    },

    updated() {
      if (this.mode !== 'add') {
        this.validateClosingTime();
      }
    },

    beforeDestroy() {
      this.initDatePickerSyncHandler(false);
    },

    data: () => ({
      invalid: true,
      fromDate: null,
      untilDate: null,
    }),

    validations: {
      closingTime: {
        name: {
          required,
        },
        from: {
          required,
        },
        until: {
          required,
        },
      },
    },

    methods: {

      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#from-date-dp').children('input').bind('blur', () => {
              this.fromDate = jQuery('#from-date-dp').children('input').val();
              this.writeBackDates();
            });
            jQuery('#until-date-dp').children('input').bind('blur', () => {
              this.untilDate = jQuery('#until-date-dp').children('input').val();
              this.writeBackDates();
            });
          }, 500);
        } else {
          jQuery('#from-date-dp').children('input').unbind('blur');
          jQuery('#until-date-dp').children('input').unbind('blur');
        }
      },

      formatFromDate() {
        if (this.closingTime && this.closingTime.from) {
          this.fromDate = Vue.moment(this.closingTime.from).format('DD.MM.YYYY');
        }
      },

      formatUntilDate() {
        if (this.closingTime && this.closingTime.until) {
          this.untilDate = Vue.moment(this.closingTime.until).format('DD.MM.YYYY');
        }
      },

      onOpenUntilDatePicker() {
        if ((this.closingTime.from && !this.closingTime.until) ||
          (this.fromDate && !this.untilDate)) {
          this.closingTime.until = this.closingTime.from;
          this.untilDate = this.fromDate;
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.closingTime[fieldName];

        if (fieldName === 'from') {
          if (Vue.moment(this.fromDate, 'DD.MM.YYYY').isBefore(Vue.moment(this.selectedYear + '-01-01', 'YYYY-MM-DD')) && field.$dirty) {
            return 'md-invalid';
          }
        }
        if (fieldName === 'until') {
          if (Vue.moment(this.untilDate, 'DD.MM.YYYY').isAfter(Vue.moment(this.selectedYear + '-12-31', 'YYYY-MM-DD')) && field.$dirty) {
            return 'md-invalid';
          }
        }

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      writeBackDates() {
        if (this.fromDate) {
          this.closingTime.from = Vue.moment(this.fromDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.closingTime.from = null;
        }

        if (this.untilDate) {
          this.closingTime.until = Vue.moment(this.untilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.closingTime.until = null;
        }
      },

      validateClosingTime() {
        this.$v.$touch();
        this.writeBackDates();
        this.invalid = this.$v.$invalid;
      },
    },

    computed: {
      disabledClosedDays() {
        return this.$store.getters.disabledClosedDays;
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 300;
  }
</style>