export default {

  name: 'ClosingTime',

  closingTime: {
    educational: false,
    fixed: false,
    from: '',
    name: '',
    closingDays: 0,
    refNo: '',
    until: '',
    valid: true,
  }
}