<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <md-icon style="color: black; font-size: xx-large!important;">timelapse</md-icon>&nbsp;&nbsp;&nbsp;Schließzeiten
          </h3>
        </div>
      </div>
      <div id="overlayLeft" style="width: 50%; top: 17%; height: 82%;" @click="onTouchOverlay"></div>

      <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Schließzeiten"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell medium-6">
          <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                    @md-selected="onSelect" style="height: 800px;">
            <md-table-toolbar>
              <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                <div class="cell large-10 medium-10 small-10">
                  <br>
                  <p class="md-title" style="margin-left: 0;">{{searched.length}} Schließzeiten <span
                      class="hide-for-medium-only hide-for-small-only">verfügbar</span></p>
                  <br>
                </div>
                <div class="cell large-2 medium-2 small-2">
                  <div v-if="sending">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <p class="md-sub-title" style="margin-left: 0;"><b>{{daysPerYear}}</b> Schließtage im Jahr</p>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <p class="md-sub-title" style="margin-left: 0;"><b>{{educationalDaysPerYear}}</b> Weiterbildungstage im Jahr</p>
                </div>

                <div class="cell large-2 medium-4 small-2">
                  <md-field>
                    <label>Jahr</label>
                    <md-select v-model="selectedYear" :disabled="sending" @md-selected="onBillingYear">
                      <md-option v-for="year of closingTimesYears" :value="year" :key="year">
                        {{year}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-8 small-10">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                 :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-4 medium-12 small-12">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche Schließzeit ..." v-model="search" @input="searchOnTable"/>
                  </md-field>
                </div>

                <div class="cell" style="padding-bottom: 15px;">
                  <div class="grid-x grid-padding-x">
                    <div class="cell large-8 hide-for-medium-only hide-for-small-only">
                    </div>
                    <div v-if="canWrite && (isAdmin || isFacilityAdmin || isManagement)" class="cell large-4 medium-12">
                      <md-button v-if="!isSage" @click="onAddClosingTime"
                                 :disabled="sending"
                                 class="md-icon-button md-raised md-primary">
                        <md-icon>add</md-icon>
                        <md-tooltip>Neue Schließzeit hinzufügen</md-tooltip>
                      </md-button>
                      <md-button @click="onDeleteClosingTime"
                                 :disabled="!selected || sending || selectedClosingTime.fixed || !(isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                 class="md-icon-button md-raised md-accent">
                        <md-icon>delete</md-icon>
                        <md-tooltip>Schließzeit löschen</md-tooltip>
                      </md-button>
                    </div>
                    <div v-else class="cell large-4 medium-12"></div>
                  </div>
                </div>
              </div>
            </md-table-toolbar>
            <md-table-empty-state v-if="search"
                                  md-label="Keine Schließzeit gefunden"
                                  :md-description="`Keine Schließzeit mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
            </md-table-empty-state>
            <md-table-row :id="item.refNo" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.refNo)"
                          md-selectable="single" :style="getStyle4Item(item)">
              <md-table-cell md-label="Name"
                             md-sort-by="name">{{ item.name }}
              </md-table-cell>
              <md-table-cell md-label="von"
                             md-sort-by="from">{{ item.from | moment("DD.MM.YYYY") }}
              </md-table-cell>
              <md-table-cell md-label="bis"
                             md-sort-by="until">{{ item.until | moment("DD.MM.YYYY") }}
              </md-table-cell>
              <md-table-cell md-label="Tage"
                             md-sort-by="numDays">{{ item.closingDays }}
              </md-table-cell>
              <md-table-cell md-label="Weiterbildung"
                             md-sort-by="educational">{{ booleanText(item.educational) }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="cell medium-6">
          <form novalidate @submit.prevent="validateClosingTime">
            <md-card v-if="!isSage" style="height: 800px;">

              <md-card-header>
                <h4 style="font-size: 1.6rem; font-weight: 300">{{selectedFacilityName}} {{selectedYear}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <router-link :to="editOpeningDaysLink"><span style="font-size: 1.2rem;">Öffnungstage:</span>
                    <md-tooltip md-direction="bottom" style="font-size: medium;">Öffnungstage bearbeiten</md-tooltip>
                  </router-link>
                  &nbsp;&nbsp;&nbsp;<span v-for="day of openingDays" :key="day" class="md-open-days">{{day}}&nbsp;&nbsp;</span>
                </h4>
                <br>
                <p v-if="selectedClosingTime" class="md-title">
                  <span class="hide-for-medium-only hide-for-small-only">Schließzeit: </span>
                  <span class="closing-time-title-thin" :style="getStyle4Item(selectedClosingTime)">{{selectedClosingTime.name}}</span>
                </p>
                <h5 v-else><i><p>Bitte wählen Sie eine Schließzeit aus</p></i></h5>
              </md-card-header>

              <md-card-content>
                <ClosingTimeForm v-if="selectedClosingTime" ref="closingTimeForm" :selectedYear="selectedYear"
                                 :closingTime="selectedClosingTime" :sending="sending" mode="update">
                </ClosingTimeForm>
                <div v-if="selectedClosingTime && !selectedClosingTime.fixed" class="cell text-left">
                  <br><br>
                  <button class="button success" style="margin-bottom: 0" :disabled="sending || pristine()" type="submit">
                    <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                  </button>
                </div>
              </md-card-content>

            </md-card>
          </form>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmSaveChangedDataDialog">
        <div v-if="lastSelectedClosingTime" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><md-icon style="color: black; font-size: xx-large!important;">timelapse</md-icon>&nbsp;&nbsp;&nbsp;Änderungen an Schließzeit bestätigen
          </md-dialog-title>
          <div class="cell">
            <md-content>Es wurden Änderungen an der Schließzeit&nbsp;<span class="closing-time-title">{{lastSelectedClosingTime.name}}</span>
              durchgeführt. Sollen diese gespeichert werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="updateClosingTime" class="button success" :disabled="sending"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</button>
            <button class="button alert" style="margin-left: 1rem;"
                    @click="confirmSaveChangedDataDialog = false"
                    type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="confirmSaveChangedDataDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteClosingTimeDialog">
        <div v-if="selectedClosingTime" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <md-icon style="color: black; font-size: xx-large!important;">timelapse</md-icon>&nbsp;&nbsp;&nbsp;Schließzeit der Einrichtung <span
              class="closing-time-title">{{selectedFacilityName}}</span> löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Schließzeit <span class="closing-time-title"><b>{{selectedClosingTime.name}}</b></span> der Einrichtung <span
                class="closing-time-title">{{selectedFacilityName}}</span> wirklich gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="deleteClosingTime" class="button alert" :disabled="sending"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              Schließzeit jetzt löschen
            </button>
            <button class="button success" style="margin-left: 1rem;" @click="showDeleteClosingTimeDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteClosingTimeDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditClosingTimeDialog :closingTime="newClosingTime" :selectedYear="selectedYear"
                             :facilityReferenceNumber="selectedFacilityReferenceNumber" mode="add"
                             :showDialog="showAddClosingTimeDialog"
                             @addClosingTimeSuccess="addClosingTimeSuccess"
                             @closeEditClosingTimeDialog="closeEditClosingTimeDialog"></EditClosingTimeDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Schließzeiten"></NoModuleCard>
    </div>
  </div>

</template>

<script>
  import jQuery from 'jquery';
  import Vue from 'vue';
  import Spinner from 'vue-simple-spinner';
  import {Md5} from 'ts-md5/dist/md5';

  import HttpErrorHandler from '../services/HttpErrorHandler';
  import FacilityService from "../services/FacilityService";
  import ClosingTimeForm from "../components/forms/ClosingTimeForm";
  import EditClosingTimeDialog from "../components/dialogs/EditClosingTimeDialog";
  import ClosingTime from "../entities/ClosingTime";
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import NoModuleCard from '../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'ClosingTimes',

    components: {
      NoFacilityCard,
      NoModuleCard,
      EditClosingTimeDialog,
      ClosingTimeForm,
      'vue-simple-spinner': Spinner,
    },

    mounted() {
      this.restoreUserSettings();
      this.reloadClosingTimes();

      HttpErrorHandler.maintainDarkMode(this);
    },

    beforeDestroy() {
      this.selectedClosingTime = null;
      this.selected = null;
    },

    data() {
      return {
        sending: false,
        closingTimes: [],
        daysPerYear: 0,
        educationalDaysPerYear: 0,
        openingDays: [],
        searched: [],
        search: null,
        selected: null,
        selectedYear: Vue.moment().format("YYYY"),
        closingTimesYears: [],

        selectedClosingTime: null,
        newClosingTime: null,
        lastSelectedClosingTime: null,
        selectedClosingTimeMd5Hash: null,

        dialogMode: 'update',

        availableFacilities: [],
        allowedFacilities: [],
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',

        selectedRowId: 0,
        selectedArrayIndex: -1,

        showExportTaxCertificatesDialog: false,
        confirmSaveChangedDataDialog: false,

        showDeleteClosingTimeDialog: false,
        showAddClosingTimeDialog: false,

        editOpeningDaysLink: '/Verwaltung/Einrichtungen',
      }
    },

    methods: {

      getStyle4Item(item) {
        if (item.fixed) {
          return 'color: gray';
        }
        return('');
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }
        if (this.selectedFacilityReferenceNumber) {
          this.editOpeningDaysLink = '/Verwaltung/Einrichtungen/' + this.selectedFacilityReferenceNumber;
        }
      },

      initData() {
        if (this.closingTimesYears.length <= 0) {
          if (Vue.moment().isAfter(Vue.moment('01.09.' + Vue.moment().format('YYYY'), 'DD.MM.YYYY'))) {
            this.closingTimesYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) + 1), 'DD-MM-YYYY').format('YYYY'));
          }
          for (let i = 0; i < 11; i++) {
            this.closingTimesYears.push(Vue.moment('01-01-' + String(Number(this.selectedYear) - i), 'DD-MM-YYYY').format('YYYY'));
          }
        }
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        for (let i = 0; i < this.availableFacilities.length; i++) {
          if (this.availableFacilities[i].referenceNumber === '*') {
            this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
          }
        }
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[0].name;
        }
        let i = 0;
        for (let facility of this.availableFacilities) {
          if (i > 0) {
            this.allowedFacilities.push(facility);
          }
          i++;
        }
        this.onSelectFacility();
        this.initData();
      },

      reloadClosingTimes() {

        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isAdmin || this.isTreasurer) {
              if (!this.selectedFacilityReferenceNumber) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              }
              this.buildAvailableFacilities();
              this.getClosingTimes();
            } else {
              if (!this.selectedFacilityReferenceNumber) {
                this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              }
              this.buildAvailableFacilities();
            }

            clearInterval(reloadIntervalId);
            jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 605px');
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getClosingTimes() {
        if (this.selectedFacilityReferenceNumber) {
          this.getFacilityClosingTimes(this.selectedFacilityReferenceNumber);
        }
      },

      getFacilityClosingTimes(referenceNumber) {
        if (!this.facilitiesNotExists) {
          this.sending = true;
          FacilityService.getClosingTimes4Year(referenceNumber, this.selectedYear)
            .then(response => {
              this.closingTimes = response.data.closingTimes;
              this.$store.commit('closingTimes', this.closingTimes);
              this.daysPerYear = response.data.daysPerYear;
              this.educationalDaysPerYear = response.data.educationalDaysPerYear;
              this.openingDays = response.data.openingDays;
              this.searchOnTable();
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Schließzeiten für Einrichtung mit Nummer '
                + referenceNumber + ' und Jahr ' + this.selectedYear);
              this.sending = false;
            })
        }
      },

      searchOnTable() {
        this.searched = this.closingTimes;
        this.searched = searchByName(this.searched, this.search);
        setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 0px 6px 10px;'), 100);
        setTimeout(() => jQuery('table').removeAttr('style'), 250);
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      onSelect(item) {
        if (item && item.refNo) {
          if (this.selectedClosingTimeMd5Hash && !this.pristine()) {
            this.onSaveChanges();
          }
          this.selectedRowId = item.refNo;
          this.selectedArrayIndex = -1;
          for (let i = 0; i < this.closingTimes.length; i++) {
            if (this.closingTimes[i].refNo === this.selectedRowId) {
              this.selectedArrayIndex = i;
            }
          }
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedClosingTime = JSON.parse(JSON.stringify(this.selected));
          this.selectedClosingTimeMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedClosingTime));
        } else {
          this.selected = null;
          this.selectedClosingTime = null;
          this.selectedClosingTimeMd5Hash = null;
        }
      },

      onSaveChanges() {
        this.lastSelectedClosingTime = this.selectedClosingTime;
        this.confirmSaveChangedDataDialog = true;
      },

      onAddClosingTime() {
        if (this.selectedClosingTimeMd5Hash && !this.pristine()) {
          this.onSaveChanges();
          return;
        }
        this.newClosingTime = JSON.parse(JSON.stringify(ClosingTime.closingTime));
        this.showAddClosingTimeDialog = true;
      },

      closeEditClosingTimeDialog() {
        this.showAddClosingTimeDialog = false;
      },

      addClosingTimeSuccess(newClosingTime, closingTimesData) {
        this.$store.commit('successMsg', 'Die Schließzeit &nbsp;<b>' + this.newClosingTime.name + '</b>&nbsp;wurde erfolgreich angelegt.');
        this.closingTimes = closingTimesData.closingTimes;
        this.$store.commit('closingTimes', this.closingTimes);
        this.daysPerYear = closingTimesData.daysPerYear;
        this.educationalDaysPerYear = closingTimesData.educationalDaysPerYear;
        this.searchOnTable();
      },

      onDeleteClosingTime() {
        this.showDeleteClosingTimeDialog = true;
      },

      deleteClosingTime() {
        if (this.selectedClosingTime) {
          this.sending = true;
          FacilityService.deleteClosingTime(this.selectedFacilityReferenceNumber, this.selectedClosingTime.refNo)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Schließzeit &nbsp;<b>' + this.selectedClosingTime.name + '</b>&nbsp;wurde erfolgreich gelöscht.');

              this.search = null;
              this.selected = null;
              this.selectedClosingTime = null;
              this.showDeleteClosingTimeDialog = false;
              this.reloadClosingTimes();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Schließzeit ' + this.selectedClosingTime.name);
              this.sending = false;
            })
        }
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          for (let fac of this.availableFacilities) {
            if (fac.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = fac.name;
            }
          }

          this.selected = null;
          this.selectedClosingTime = null;
          this.selectedClosingTimeMd5Hash = null;

          if (this.selectedFacilityReferenceNumber === '*') {
            this.getClosingTimes();
          } else {
            this.getFacilityClosingTimes(this.selectedFacilityReferenceNumber);
          }

          this.editOpeningDaysLink = '/Verwaltung/Einrichtungen/' + this.selectedFacilityReferenceNumber;
        }
      },

      onBillingYear() {
        this.onSelectFacility();
      },

      pristine() {
        return (this.selectedClosingTimeMd5Hash === Md5.hashAsciiStr(JSON.stringify(this.selectedClosingTime)));
      },

      onTouchOverlay() {
      },

      validateClosingTime() {
        if (this.dialogMode === 'update') {
          this.$refs.closingTimeForm.validateClosingTime();

          if (!this.$refs.closingTimeForm.invalid) {
            this.onSaveChanges();
          } else {
            // this.confirmMissingData();
          }
        }
      },

      updateClosingTime() {
        this.sending = true;
        FacilityService.updateClosingTime(this.selectedFacilityReferenceNumber, this.lastSelectedClosingTime.refNo, this.lastSelectedClosingTime)
          .then(response => {
            this.closingTimes = response.data.closingTimes;
            this.$store.commit('closingTimes', this.closingTimes);
            this.daysPerYear = response.data.daysPerYear;
            this.educationalDaysPerYear = response.data.educationalDaysPerYear;
            this.openingDays = response.data.openingDays;
            this.searchOnTable();
            this.sending = false;

            this.$store.commit('successMsg', 'Die Änderungen an der Schließzeit &nbsp;<b>' + this.lastSelectedClosingTime.name + '</b>&nbsp;wurden erfolgreich durchgeführt.');

            this.confirmSaveChangedDataDialog = false;
            this.selectedClosingTimeMd5Hash = null;
            if (this.selectedArrayIndex < this.closingTimes.length) {
              this.selectedClosingTime = this.closingTimes[this.selectedArrayIndex];
              this.selectedRowId = this.closingTimes[this.selectedArrayIndex].refNo;
            }
            this.onSelect(this.selectedClosingTime);
            setTimeout(() => jQuery('#' + this.selectedRowId).trigger('click'), 250);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Schließzeit "' + this.lastSelectedClosingTime.name + '"');
            this.sending = false;
          })
      },

    },

    computed: {

      facilitiesNotExists() {
        return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
      },

      dataAvailable() {
        return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
            this.user.allowedFacilities.length > 0 &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacility() {
        return this.$store.getters.facilityAdmin;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },

      labels() {
        return this.$store.getters.labels
      },

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .closing-time-title {
    color: chocolate;
    font-weight: bold;
    font-size: larger;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-sub-title {
    color: lightseagreen;
    font-weight: 400;
    font-size: 1rem;
  }

  .md-open-days {
    color: cornflowerblue;
    font-weight: 300;
    font-size: 1.2rem;
    text-underline: cornflowerblue;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .closing-time-title-thin {
    color: chocolate;
    font-weight: lighter;
    font-size: larger;
  }

</style>
